import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import { motion, useTransform, useMotionValue, useViewportScroll } from "framer-motion"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import "../scss/home-mobile-style.scss"
import HeroSectionMobile from "components/pages/Home/Mobile/HeroSection/HeroSection"
import TheWayYouWorkSectionMobile from "components/pages/Home/Mobile/TheWayYouWork/TheWayYouWorkSection"
import RideWithTranscendSection from "components/pages/Home/Mobile/RideWithTranscend/RideWithTranscendSection"
import MapPhone from "images/map-phone-img.png"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const ContactThankYouPage: React.FC<PageProps<DataProps>> = ({ location }) => {
  const [yScroll, setYScroll] = useState(0);
  const { scrollY } = useViewportScroll();

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Layout>
        <Seo title="Transcend Services, Inc. | Medical Transportation | Translation-Interpretation Services"/>
        <section id="mobile-layout">
          <HeroSectionMobile />
          <TheWayYouWorkSectionMobile />
          <RideWithTranscendSection />
          <section id="mobile-section">
            <Container>
              <div className="col-lg-12">
                <div id="get-there-container">
                  <h2 id="second-header">Get There on Time Every Time</h2>
                  <img src={MapPhone} className="map-phone-img" />
                  <p className="text-white text-center">Our drivers are best in class. Coupled with professionalism and industry leading technology we guarantee safety and reliability.</p>
                  <a href="/Transportation-Services">
                    <button id="btn-learn-more">Learn More</button>
                  </a>
                </div>
              </div>
            </Container>
          </section>
        </section>

      </Layout>
    </>
  )
}

export default ContactThankYouPage
