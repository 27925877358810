import React, { useState, useEffect } from 'react'
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"


import Layout from "components/layout"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import "components/pages/Home/Mobile/RideWithTranscend/RideWithTranscendStyle.scss"
import { motion, useTransform, useMotionValue, useViewportScroll } from "framer-motion"
import SignalGradient from "images/signal-gradientpink.png"
import PurpleGlow from "images/purple-glow 2.png"
import HorizontalPhone from "images/phone-map-img.png"
import MapPhone from "images/map-phone-img.png"

type DataProps = {
  site: {
    buildTime: string
  }
}


//responsive from 1025 to 950
export const useSmallDesktopMin = () => useMediaQuery('(min-width: 950px)');
export const useSmallDesktopMax = () => useMediaQuery('(max-width: 1025px)');

//responsive from 800 to 949
export const useTabletMax = () => useMediaQuery('(max-width: 949px)');
export const useTabletMin = () => useMediaQuery('(min-width: 699px)');

// responsive
export const use499 = () => useMediaQuery('(max-width: 449px)');
export const use425 = () => useMediaQuery('(max-width: 425px)');
export const use320 = () => useMediaQuery('(min-width: 320px)');

export const use698 = () => useMediaQuery('(max-width: 698px)');
export const use500 = () => useMediaQuery('(max-width: 500px)');

export const use501 = () => useMediaQuery('(min-width: 501px)');


export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}


const RideWithTranscendSection: React.FC<PageProps<DataProps>> = ({ path }) => {

  const [yScroll, setYScroll] = useState(0);

  const data = useStaticQuery(
    graphql`
    query {
      phone: file(relativePath: { eq: "phone-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      signal_gradient: file(relativePath: { eq: "signal-gradientpink.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:1263
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      small_car: file(relativePath: { eq: "small-car-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:20
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      purple_glow: file(relativePath: { eq: "purple-glow 2.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      horizontal_phone: file(relativePath: { eq: "phone-map-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:550
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      phone_map: file(relativePath: { eq: "map-phone-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:572
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `
  )


  const isSmallDesktopMin = useSmallDesktopMin()
  const isSmallDesktopMax = useSmallDesktopMax()
  const isTabletMax = useTabletMax()
  const isTabletMin = useTabletMin()
  const is499 = use499()
  const is320 = use320()
  const is698 = use698()
  const is500 = use500()
  const is425 = use425()
  const is501 = use501()

  const phone = getImage(data.phone)
  const signal_gradient = getImage(data.signal_gradient)
  const small_car = getImage(data.small_car)
  const purple_glow = getImage(data.purple_glow)
  const horizontal_phone = getImage(data.horizontal_phone)
  const phone_map = getImage(data.phone_map)
  // Use like this:initial={{y:-2800,x:0}}

  const { scrollY } = useViewportScroll();

  scrollY.onChange((y) => {
    setYScroll(y);
  })

  const textNewY = useTransform(scrollY, [4631, 4831, 5031, 5531], ["100vh", "20vh", "20vh", "-100vh"])
  const textNewY698 = useTransform(scrollY, [4631, 4831, 5031, 5531], ["100vh", "30vh", "30vh", "-100vh"])
  const textNewY500 = useTransform(scrollY, [4631, 5231, 6031], [950, 250, -950])
  const textOpacity = useTransform(scrollY, [4600, 5231, 5931, 6031], [0.1, 1, 1, 0.1])
  const horizontalPhoneNewX = useTransform(scrollY, [5031, 6031], [2000, 50])

  const textResponsive =
    is500 && is320 ?
      {
        opacity: textOpacity,
        y: textNewY500
      } :
      {
        opacity: textOpacity,
        y: textNewY
      }

  //y:textNewY
  return (
    <section id="ride-with-transcend">
      <center>
        <motion.div id="heading-container" style={textResponsive} >
          <h2 id="sub-heading">RIDE WITH</h2>
          <h1 id="heading">TRANSCEND</h1>
        </motion.div>
      </center>
    </section>
  )

}

export default RideWithTranscendSection
