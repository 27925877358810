import React, { useState, useEffect } from 'react'
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import "components/pages/Home/Mobile/HeroSection/HeroSectionStyle.scss"
import { motion, useTransform, useMotionValue, useViewportScroll, AnimatePresence } from "framer-motion"
import Car from "images/car-image.png"
import Car2 from "images/Big-Car.png"
import { calculateImageSizes } from 'gatsby-plugin-image/dist/src/image-utils'


type DataProps = {
  site: {
    buildTime: string
    header: header
  }
}

//responsive from 1025 to 950
export const useSmallDesktopMin = () => useMediaQuery('(min-width: 950px)');
export const useSmallDesktopMax = () => useMediaQuery('(max-width: 1025px)');

//responsive from 800 to 949
export const useTabletMax = () => useMediaQuery('(max-width: 949px)');
export const useTabletMin = () => useMediaQuery('(min-width: 501px)');

// responsive
export const use769 = () => useMediaQuery('(max-width: 769px)');
export const use499 = () => useMediaQuery('(max-width: 500px)');
export const use320 = () => useMediaQuery('(min-width: 320px)');

// export const useMobile = () => useMediaQuery('(min-width: 320px)');
// export const useMidMobile = () => useMediaQuery('(min-width: 500px)');

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}



const HeroSection: React.FC<PageProps<DataProps>> = ({ path }) => {

  const data = useStaticQuery(
    graphql`
        query {
          bg: file(relativePath: { eq: "hero-bg.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          car: file(relativePath: { eq: "car-image.png" }) {
            childImageSharp {
              gatsbyImageData(
                quality:100
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      `
  )

  const image = getImage(data.bg)
  // Use like this:
  const bgImage = convertToBgImage(image)
  const car = getImage(data.car)
  const [headerSwitch, setHeaderSwitch] = useState("firstState");
  const { scrollY } = useViewportScroll();

  // Animated width in relation to the scrolled amount
  const scaleWidth = useTransform(scrollY, [0, 100], [.1, .3])
  const scaleY = useTransform(scrollY, [0, 100], [0, -100])
  //const scaleY500 = useTransform(scrollY, [0, 200], [0, 100])
  const scaleY500 = useTransform(scrollY, [0, 200], [-100, 0])
  const scaleWidth1024 = useTransform(scrollY, [0, 301], [1, 3])
  const scaleWidth768 = useTransform(scrollY, [0, 301], [1, 4])
  const scaleWidth320 = useTransform(scrollY, [0, 200], [.85, 3.2])
  // const carNewY = useTransform(scrollY, [0, 301], [80, 5])
  const [heroSectionVisible, setHeroSectionVisible] = useState(1)
  scrollY.onChange((y) => {
    //console.log(y)
    if (y > 351) {
      setHeroSectionVisible(0)
    } else {
      setHeroSectionVisible(1)
    }
    // y = scroll position
    //console.log(y)
  })
  const headerNewY = useTransform(
    scrollY,
    [0, 451, 1201],
    [0, 0, 0]
  )

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (headerSwitch == "firstState") {
        setHeaderSwitch("secondState");
      } else {
        setHeaderSwitch("firstState");
      }
    }, 23200);
    return () => clearInterval(interval);
  }, [headerSwitch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeaderSwitch("firstState");
    }, 23201);
  }, [headerSwitch]);

  //animate={{y:45, opacity:0}} initial={{ opacity:1}} transition={{delay:5, duration:1}} panimate={{opacity:1}} transition={{delay:1, duration:1}} initial={{opacity:0}}
  //

  const isSmallDesktopMin = useSmallDesktopMin()
  const isSmallDesktopMax = useSmallDesktopMax()
  const isTabletMax = useTabletMax()
  const isTabletMin = useTabletMin()
  const is499 = use499()
  const is320 = use320()
  const is769 = use769()

  const heroCarVariants =
    isSmallDesktopMin && isSmallDesktopMax ?
      {
        y: "-7vh",
        scale: scaleWidth1024
      } : isTabletMin && isTabletMax ?
        {
          y: 0,
          scale: scaleWidth768
        }
        : is320 && is499 ? {
          // y: -100,
          y: scaleY500,
          scale: scaleWidth320,
          opacity: 1
        } : { scale: scaleWidth, y: scaleY }


  const clientsHeaderVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 1, duration: 1, ease: "easeInOut" },
      y: -30,
    },
    hidden: {
      opacity: 0,
    },

    exit: {
      opacity: 0,
      y: -60,
    },

  }

  const interpretersHeaderVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 3, duration: 1, ease: "easeInOut" },
      y: -25,
    },
    hidden: {
      opacity: 0,
    },

    exit: {
      opacity: 0,
      y: -60,
    },

  }

  const driversHeaderVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 6, duration: 1, ease: "easeInOut" },
      y: -25,
    },
    hidden: {
      opacity: 0,
    },

    exit: {
      opacity: 0,
      y: -60,
    },

  }

  const referralsHeaderVariants = {
    visible: {
      opacity: 1,
      transition: { delay: 9, duration: 1, ease: "easeInOut" },
      y: -25,
    },
    hidden: {
      opacity: 0,
    },

    exit: {
      opacity: 0,
      y: -60,
    },

  }

  const [showClientsHeader, setShowClientsHeader] = useState(true);
  const [showInterpretersHeader, setShowInterpretersHeader] = useState(true);
  const [showDriversHeader, setShowDriversHeader] = useState(true);
  const [showReferralsHeader, setShowReferralsHeader] = useState(true);

  setTimeout(() => {
    setShowClientsHeader(false);
  }, 3000);

  setTimeout(() => {
    setShowInterpretersHeader(false);
  }, 6000);

  setTimeout(() => {
    setShowDriversHeader(false);
  }, 9000);

  const [isVisible, setIsVisible] = useState(true);
  const [current, setCurrent] = useState(0);

  /* end of start of testing infinite loop hero header*/


  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div id="homepage-hero-wrapper" animate={{ opacity: heroSectionVisible }} exit={{ opacity: 0 }}>
        <motion.section id="hero" animate={{ opacity: heroSectionVisible }} exit={{ opacity: 0 }} >
          <center>
            {headerSwitch === "firstState" ?
              <motion.div style={{ y: headerNewY }}>
                <motion.div initial={{ opacity: 1 }} animate={{ y: -15, opacity: 0 }} transition={{ delay: 5, duration: 1 }} className="hero-header-container-1" >
                  <motion.h2 className="hero-header" initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: -10 }} transition={{ delay: 1, duration: 1 }} >Transcend Clients</motion.h2>
                  <motion.div animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 1 }} initial={{ opacity: 0 }} ><motion.p initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: -10 }} transition={{ delay: 2.5, duration: 1 }}>Experience customer service beyond normal limits.</motion.p></motion.div>
                </motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ y: -15, opacity: 1 }} transition={{ delay: 6, duration: 1 }} className="hero-header-container">
                  <motion.div animate={{ y: -15, opacity: 0 }} transition={{ delay: 11, duration: 1.5 }}><h2 className="hero-header-2">Transcend Interpreters</h2></motion.div>
                  <motion.div animate={{ opacity: 1, y: 0 }} transition={{ delay: 7, duration: 1 }} initial={{ opacity: 0, y: 15 }}><motion.p animate={{ y: -15, opacity: 0 }} transition={{ delay: 11, duration: 1.5 }}>Join our team, get paid, and make a difference.</motion.p></motion.div>
                </motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ y: -15, opacity: 1 }} transition={{ delay: 12.5, duration: 1 }} className="hero-header-container">
                  <motion.div animate={{ y: -15, opacity: 0 }} transition={{ delay: 17.5, duration: 1.5 }}><h2 className="hero-header">Transcend Drivers</h2></motion.div>
                  <motion.div animate={{ opacity: 1, y: 0 }} transition={{ delay: 13.5, duration: 1 }} initial={{ opacity: 0, y: 15 }}><motion.p animate={{ y: -15, opacity: 0 }} transition={{ delay: 17.5, duration: 1.5 }}>Drive and get paid on one easy platform.</motion.p></motion.div>
                </motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ y: -15, opacity: 1 }} transition={{ delay: 19, duration: 1 }} className="hero-header-container">
                  <motion.div animate={{ y: -15, opacity: 0 }} transition={{ delay: 22, duration: 1.5 }}><h2 className="hero-header">Transcend Referrals</h2></motion.div>
                  <motion.div animate={{ opacity: 1, y: 0 }} transition={{ delay: 20, duration: 1 }} initial={{ opacity: 0, y: 15 }}>
                    <motion.p animate={{ y: -15, opacity: 0 }} transition={{ delay: 22, duration: 1.5 }}>
                      Referral management without  the hassle.
                    </motion.p>
                  </motion.div>
                </motion.div>
              </motion.div>
              :
              null
            }
            {is499 &&
              <motion.div style={heroCarVariants} initial={{ scale: .85, y: -100 }} className="hero-car-container mobile">
                <img src={Car2} className="hero-car-img" />
              </motion.div>
            }

            <motion.div style={heroCarVariants} className="hero-car-container desktop">
              <img src={Car2} className="hero-car-img" />
            </motion.div>
          </center>
        </motion.section>
      </motion.div>
    </AnimatePresence>
  )

}

export default HeroSection
