import React, { useState, useEffect } from 'react'
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import "components/pages/Home/Mobile/TheWayYouWork/TheWayYouWorkStyle.scss"
import { motion, useTransform, useMotionValue, useViewportScroll } from "framer-motion"
import Phone from "images/phone-img.png"
import RollingCar from "images/rolling-car.png"
import SignalGradient from "images/signal-gradientpink.png"
import PurpleGlow from "images/purple-glow 2.png"
import SmallCar from "images/small-car-img.png"
import HorizontalPhone from "images/phone-map-img.png"
import MapPhone from "images/map-phone-img.png"

type DataProps = {
  site: {
    buildTime: string
  }
}

//responsive from 1025 to 950
export const useSmallDesktopMin = () => useMediaQuery('(min-width: 950px)');
export const useSmallDesktopMax = () => useMediaQuery('(max-width: 1025px)');

//responsive from 800 to 949
export const useTabletMax = () => useMediaQuery('(max-width: 949px)');
export const useTabletMin = () => useMediaQuery('(min-width: 699px)');

// responsive
export const use425 = () => useMediaQuery('(max-width: 425px)');
export const use320 = () => useMediaQuery('(min-width: 320px)');
export const use375 = () => useMediaQuery('(max-width: 375px)');

export const use698 = () => useMediaQuery('(max-width: 698px)');
export const use501 = () => useMediaQuery('(min-width: 501px)');
export const use426 = () => useMediaQuery('(min-width: 426px)');
export const use500 = () => useMediaQuery('(max-width: 500px)');

export const use1920 = () => useMediaQuery('(max-width: 1920px)');

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

const TheWayYouWorkSection: React.FC<PageProps<DataProps>> = ({ path }) => {
  const data = useStaticQuery(
    graphql`
    query {
      phone: file(relativePath: { eq: "phone-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:384
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      signal_gradient: file(relativePath: { eq: "signal-gradientpink.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:1263
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      small_car: file(relativePath: { eq: "small-car-img.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:20
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      purple_glow: file(relativePath: { eq: "purple-glow 2.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      rolling_car: file(relativePath: { eq: "rolling-car.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality:100
            width:700
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `
  )

  const phone = getImage(data.phone)
  const rolling_car = getImage(data.rolling_car)
  const signal_gradient = getImage(data.signal_gradient)
  const small_car = getImage(data.small_car)
  const purple_glow = getImage(data.purple_glow)
  // Use like this:initial={{y:-100}}

  const { scrollY } = useViewportScroll();
  const [relativeContainer, setRelativeContainer] = useState("");

  const scaleWidth = useTransform(
    scrollY,
    [301, 801, 1301, 2501],
    [0.7, 0.9, 0.7, 1]
  )

  const scaleWidth698 = useTransform(
    scrollY,
    [301, 801, 1301, 2501],
    [0.9, 1.1, 0.9, 1.1]
  )

  const scaleWidth500 = useTransform(
    scrollY,
    [301, 801, 1301, 2001, 2501, 5101, 5501],
    [1.2, 1.4, 1.2, 1.2, 1.4, 1.4, 1]
  )

  const phoneY500 = useTransform(
    scrollY,
    [301, 5301, 5601],
    [180, 180, 100]
  )


  const phoneOpacity = useTransform(
    scrollY,
    [0, 401, 801, 1301, 2001, 2501, 4031, 4331, 5431, 6531, 7901, 8501],
    [0, 0, 1, 0.1, 0.1, 1, 1, 0.06, 0.06, 1, 1, 0]
  )

  const phoneOpacity500 = useTransform(
    scrollY,
    [0, 401, 601, 1501, 2101, 2501, 3731, 4331, 5431, 5601, 6001, 6501, 7001],
    [0, 0, 1, 0.1, 0.1, 1, 1, 0.1, 0.1, 1, 1, 0, 0]
  )


  const phoneNewY = useTransform(scrollY, [351, 801], ["100vh", "0vh"])
  const phoneNewY768 = useTransform(scrollY, [351, 801], ["100vh", "6vh"])
  const phoneNewY698 = useTransform(scrollY, [351, 801], ["100vh", "15vh"])
  const phoneNewY500 = useTransform(scrollY, [351, 801], [100, 100])

  const phoneTextOpacity = useTransform(scrollY, [1200, 1600, 2000, 2200], [0.1, 1, 1, 0.1])

  const textNewY = useTransform(
    scrollY,
    [801, 1501, 2001, 2501],
    ["100vh", "0vh", "0vh", "-100vh"]
  )

  const textNewY698 = useTransform(
    scrollY,
    [801, 1501, 2001, 2501],
    ["100vh", "20vh", "20vh", "-100vh"]
  )

  const textNewY500 = useTransform(
    scrollY,
    [801, 1501, 2001, 2501],
    [600, 50, 50, -600]
  )

  const carNewX = useTransform(scrollY, [2501, 3231], ["-100vw", "68vw"])
  const carNewX500 = useTransform(scrollY, [2501, 3231], [-900, 270])
  const [carVisible, setCarVisible] = useState(1)
  scrollY.onChange((y) => {
    if (y > 3131) {
      setCarVisible(0)
    } else {
      setCarVisible(1)
    }
  })

  const smallCarNewY = useTransform(scrollY, [3331, 3931], ["65vh", "0vh"])
  const smallCarNewY1920 = useTransform(scrollY, [3331, 3931], ["55vh", "0vh"])
  const smallCarNewY1024 = useTransform(scrollY, [3331, 3931], ["60vh", "10vh"])
  const smallCarNewY768 = useTransform(scrollY, [3331, 3931], ["55vh", "4vh"])
  const smallCarNewY698 = useTransform(scrollY, [3331, 3931], [350, 70])
  const smallCarNewY500 = useTransform(scrollY, [3331, 3931], [290, 90])
  const smallCarNewY425 = useTransform(scrollY, [3331, 3931], ["43vh", "2vh"])
  const [smallCarVisible, setSmallCarVisible] = useState(0)
  const purpleGlowVisible = useTransform(
    scrollY,
    [2331, 2501, 3801, 4301, 4831, 5431, 6331, 7031, 7531, 8531],
    [0, 0.2, 1, 0, 0, 0.1, 0, 0, 1, 0]
  )

  const [yScroll, setYScroll] = useState(0);

  scrollY.onChange((y) => {
    setYScroll(y);
    if (y > 6500) {
      setRelativeContainer("relative-container");
    } else {
      setRelativeContainer("");
    }
    if (y > 3251 && y < 3951) {
      setSmallCarVisible(1)
    } else {
      setSmallCarVisible(0)
    }
  })

  const purpleGlowScale = useTransform(
    scrollY,
    [3231, 3431, 3631, 4131, 5201, 6631],
    [1, 1.5, 1.7, 1.9, 2.1, 1.5]
  )

  const purpleGlowX = useTransform(
    scrollY,
    [6331, 8531],
    ["0vw", "-30vw"]
  )

  const purpleGlowX1024 = useTransform(
    scrollY,
    [6331, 8531],
    ["0vw", "-40vw"]

  )

  const ellipseScale = useTransform(
    scrollY,
    [3231, 3431, 3631, 4131, 5011, 5201, 7001, 7331, 7501, 7701, 7901],
    [.6, .8, .9, 1.8, 0, 0, 0, .8, 1, 1.2, 1.4]
  )

  const ellipseScale768 = useTransform(
    scrollY,
    [3231, 3431, 3631, 4131, 5011, 5201, 7001, 7331, 7501, 7701, 7901],
    [.8, 1, 1.1, 2.1, 0, 0, 0, 1, 1.2, 1.4, 1.6]
  )

  const ellipseScale500 = useTransform(
    scrollY,
    [3231, 3431, 3631, 4131, 5011, 5501],
    [.8, 1.1, 1.4, 2.4, 0, 1.8]
  )

  const ellipseNewY = useTransform(
    scrollY,
    [3031, 3231, 5831, 5831],
    [-1600, -1650, -1650, -1650]
  )

  const ellipseOpacity = useTransform(
    scrollY,
    [2331, 2501, 3801, 4401, 4831, 5231, 6301, 7103, 7301, 7501, 7701, .7901, 8101, 8501],
    [0, 0.2, 1, 0, 0, 0, 0, .3, .5, .65, .7, .8, .8, 0]

  )

  const ellipseOpacity500 = useTransform(
    scrollY,
    [2331, 2501, 3801, 4401, 4731, 5300, 5700, 6001, 6501],
    [0, 0.2, 1, 0, 0, 0, 1, 1, 0]
  )

  // 5400 to 6800

  const phoneNewX = useTransform(
    scrollY,
    [5031, 6131, 6331, 6831],
    ["0vw", "-22vw", "-22vw", "-22vw"]
  )

  const phoneNewX500 = useTransform(
    scrollY,
    [4531, 5431],
    ["0vw", "-23vw"]
  )

  const ellipseNewX = useTransform(
    scrollY,
    [4531, 5031, 5831, 6331],
    ["0vw", "-21vw", "-21vw", "-21vw"]
  )

  const ellipseNewX1024 = useTransform(
    scrollY,
    [4531, 5031],
    ["0vw", "-22.3vw"]
  )

  const horizontalPhoneNewX = useTransform(scrollY, [6031, 6731], ["100vw", "0vw"])//const horizontalPhoneNewX = useTransform(scrollY, [6031, 7031], ["100vw", "0vw"])
  const horizontalPhoneNewX500 = useTransform(scrollY, [5231, 5731], [900, 5])
  const horizontalPhoneNewOpacity = useTransform(scrollY, [5031, 7901, 8501], [1, 1, 0])
  const horizontalPhoneNewOpacity500 = useTransform(scrollY, [0, 6001, 6501, 7001], [1, 1, 0, 0])
  const mapPhoneNewX = useTransform(scrollY, [6031, 6731, 8131, 8531], ["100vw", "5vw", "5vw", "20vw"]) //const mapPhoneNewX = useTransform(scrollY, [6031, 7031, 8131, 8531], ["100vw", "5vw","5vw","20vw"])
  const mapPhoneNewX768 = useTransform(scrollY, [6031, 6731, 8131, 8531], ["100vw", "11vw", "11vw", "20vw"])
  const mapPhoneNewX698 = useTransform(scrollY, [6031, 6731, 8131, 8531], ["100vw", "9vw", "9vw", "20vw"])
  const mapPhoneNewX500 = useTransform(scrollY, [5231, 5731, 5931, 7000, 7200], [900, 75, 75, 75, 75])
  const mapPhoneNewX425 = useTransform(scrollY, [6031, 6731, 8131, 8531], ["100vw", "6vw", "6vw", "9.5vw"])
  const mapScale = useTransform(scrollY, [7031, 8131, 8531], [.95, .95, 1.35])
  const mapScale768 = useTransform(scrollY, [7031, 8131, 8531], [1.2, 1.2, 1.7])
  const mapScale500 = useTransform(scrollY, [7031, 7201], [.4, .4])
  const mapScaleY = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [7831, 9501, 10300],
    ["0vh", "3vh", "-100vh"]
  )

  const mapScaleY768 = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [7831, 9501, 10300],
    ["7vh", "8vh", "-100vh"]
  )

  const mapScaleY698 = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [7831, 9501, 10300],
    ["14vh", "8vh", "-100vh"]
  )

  const mapScaleY500 = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [7001, 7201, 8451],
    [155, 155, 155]
  )

  const mapScaleOpacity500 = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [0, 6001, 6501, 7201],
    [1, 1, 0, 0]
  )

  const theWayYouWorkY = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [6501, 7501],
    [0, -1000]
  )

  const theWayYouWorkOpacity = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [0, 6001, 6501, 7001],
    [1, 1, 0, 0]
  )

  const mapScaleY425 = useTransform(
    scrollY,
    //[7131, 8001, 8800],
    [7831, 9501, 10300],
    ["1vh", "0vh", "-100vh"]
  )

  const isSmallDesktopMin = useSmallDesktopMin()
  const isSmallDesktopMax = useSmallDesktopMax()
  const isTabletMax = useTabletMax()
  const isTabletMin = useTabletMin()
  const is425 = use425()
  const is320 = use320()
  const is698 = use698()
  const is501 = use501()
  const is500 = use500()
  const is426 = use426()
  const is375 = use375()
  const is1920 = use1920()

  const smallCarResponsive =
    isSmallDesktopMax && isTabletMin ?
      {
        y: smallCarNewY1024
      } : is501 && is698 ?
        {
          y: smallCarNewY698
        } :
        is500 && is320 ? {
          y: smallCarNewY500
        } :
          is1920 ? {
            y: smallCarNewY1920
          } :
            {
              y: smallCarNewY
            }

  const ellipseResponsive =
    is500 && is320 ?
      {
        scale: ellipseScale500,
        opacity: ellipseOpacity500,
        x: ellipseNewX1024,
        y: 100
      } :
      {
        scale: ellipseScale,
        opacity: ellipseOpacity,
        x: ellipseNewX
      }

  const purpleGlowXResponsive =
    is500 && is320 ? {
      scale: purpleGlowScale,
      opacity: purpleGlowVisible,
      x: ellipseNewX1024,
      y: 150
    }
      : {
        scale: purpleGlowScale,
        opacity: purpleGlowVisible,
        x: ellipseNewX
    }

  const phoneResponsive =
    is500 && is320 ?
      {
        scale: scaleWidth,
        y: phoneNewY500,
        x: phoneNewX500,
        opacity: phoneOpacity500
      }
      : {
        scale: scaleWidth,
        x: phoneNewX,
        opacity: phoneOpacity
      }

  const horizontalPhoneResponsive =
    isTabletMax && isTabletMin ? {
      x: horizontalPhoneNewX,
      opacity: horizontalPhoneNewOpacity,
      y: "8vh",
      scale: 1.3
    } : is501 && is698 ? {
      x: horizontalPhoneNewX,
      opacity: horizontalPhoneNewOpacity,
      y: "15vh",
      scale: 1.2
    } :
      is500 && is320 ? {
        x: horizontalPhoneNewX500,
        opacity: horizontalPhoneNewOpacity500,
        y: 60
      } :
        {
          x: horizontalPhoneNewX,
          opacity: horizontalPhoneNewOpacity
        }

  const mapPhoneResponsive =
    isTabletMax && isTabletMin ? {
      x: mapPhoneNewX768,
      scale: mapScale768,
      y: mapScaleY768
    } : is698 && is501 ? {
      x: mapPhoneNewX698,
      scale: mapScale,
      y: mapScaleY698
    } :
      is500 && is320 ? {
        x: mapPhoneNewX500,
        scale: mapScale500,
        y: mapScaleY500,
        opacity: mapScaleOpacity500
      } :
        {
          x: mapPhoneNewX,
          scale: mapScale,
          y: mapScaleY
        }

  const textResponsive =
    is500 && is320 ?
      {
        opacity: phoneTextOpacity,
        y: textNewY500
      } :
      {
        opacity: phoneTextOpacity,
        y: textNewY
      }

  const rollingCarResponsive =
    is500 && is320 ? {
      x: carNewX500,
      y: 50
    } :
      {
        x: carNewX
      }

  return (
    <motion.section id="the-way-you-work" className={relativeContainer} style={{opacity: theWayYouWorkOpacity}}>
      <center>
        <motion.div>
          <motion.div animate={{ opacity: carVisible }} transition={{ duration: .5 }} style={rollingCarResponsive} id="rolling-car-container">
            <img src={RollingCar} className="rolling-car-img" />
          </motion.div>
          <motion.div style={horizontalPhoneResponsive} id="horizontal-phone-container">
            <img src={HorizontalPhone} className="horizontal-phone-img" />
          </motion.div>
          <motion.div style={mapPhoneResponsive} id="map-phone-container" >
            <img src={MapPhone} className="map-phone-img" />
          </motion.div>
          <motion.div style={phoneResponsive} id="phone-container">
            <img src={Phone} className="phone-img" />
          </motion.div>
        </motion.div>
        <motion.div style={smallCarResponsive} animate={{ opacity: smallCarVisible }} transition={{ duration: .5 }} initial={{ opacity: 0 }} id="small-car-container">
          <img src={SmallCar} className="small-car-img" />
        </motion.div>
        <motion.div transition={{ duration: 3 }} style={ellipseResponsive} id="ellipse-container">
          <img src={SignalGradient} className="ellipse-img" />
        </motion.div>
        <motion.div id="purple-glow-container" style={purpleGlowXResponsive}>
          <img src={PurpleGlow} className="purple-glow-img" />
        </motion.div>
        <motion.div style={textResponsive} id="heading-container">
          <h1 className="header">Transcend the Way You Work</h1>
          <h2 className="sub-header">Book Rides. Book Interpretation. Refer Both.</h2>
          <p className="heading-p">Transcend provides an online platform to manage rides, <br />interpretation, and referrals.</p>
        </motion.div>
      </center>
    </motion.section>
  )

}

export default TheWayYouWorkSection
